import { Form } from "react-bootstrap";
import { Row, Col, Table, Radio, Card, Button } from "antd";
import { apiUrl } from "../../helper/endpoint";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import React, { useEffect, useRef, useState } from "react";
import { saveCoupons } from "../../store/actions/couponAction";
import BottomFooter from "../Footer/BottomFooter";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";
import { CheckCircleOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

function TflGiftCardOthers(props) {
  const [show, setShow] = useState(true);
  const [isDisabled, setisDisabled] = useState(false);
  const [tflGiftCardAmt, setTflGiftCardAmt] = useState("");
  const { pathname } = useLocation();
  let [confirmCountryCode, setCountryCodeConfirm] = useState("");
  const dispatch = useDispatch();
  let emailRef = useRef("");
  let confirmEmailRef = useRef("");
  let coupanValueRef = useRef("");

  let recipientsRef = useRef("");
  let recipientsEmailRef = useRef("");
  let recipientsPhoneCodeRef = useRef("");
  let recipientsPhoneRef = useRef("");

  let countrycodeRef = useRef("");
  let phoneNumberRef = useRef("");
  let confirmphoneNumberRef = useRef("");
  const user = useSelector((state) => state.userReducer);

  let userId = localStorage.getItem("userId");
  let [errorObj, setErrorObj] = useState({
    emailError: false,
    couponError: false,
    phoneNumberError: false,
    confirmphoneNumberError: false,
    coupanValueError: false,
    conifirmEmailError: false
  });

  useEffect(() => {
    if (user) {
      if (user.email) {
        emailRef.current.value = user.email;
      }
      if (user.phone) {
        phoneNumberRef.current.value = user.phone;
      }
      if (user.countryCode) {
        countrycodeRef.current.value = user.countryCode;
      }
      // console.log("user", user);
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (value) => {
    setSelectedOption(value);
  };
  useEffect(() => {
    const fetchGiftCardData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/treeDrive/tdlcoupon/TFLGiftRange`
        );
        // console.log(response.data);
        setTflGiftCardAmt(response.data.data || []);
      } catch (error) {
        console.error("Error fetching Agent card data:", error);
      }
    };

    fetchGiftCardData();
  }, []);
  const onSubmitCoupon = () => {
    let isFormVaild = true;
    setisDisabled(true);
    if (emailRef.current.value == "" && !emailRef.current.value.includes("@")) {
      isFormVaild = false;
      setErrorObj((errState) => {
        return { ...errState, emailError: true };
      });
      // console.log("emailRef.current.value", emailRef.current.value);
    }
    if (phoneNumberRef.current.value.length < 6) {
      isFormVaild = false;
      setErrorObj((errState) => {
        return { ...errState, phoneNumberError: true };
      });
    }
    if (recipientsPhoneRef.current.value.length < 6) {
      isFormVaild = false;
      setErrorObj((errState) => {
        return { ...errState, recipientsPhoneError: true };
      });
    }

    if (!selectedOption) {
      isFormVaild = false;
      setErrorObj((errState) => ({ ...errState, coupanValueError: true }));
    }

    if (coupanValueRef.current.value === "") {
      isFormVaild = false;
      setErrorObj((errState) => ({ ...errState, coupanValueError: true }));
    }

    if (isFormVaild) {
      dispatch(
        saveCoupons({
          email: emailRef.current.value,
          confirmEmail: emailRef.current.value,
          phoneNumber: phoneNumberRef.current.value,
          confirmphoneNumber: phoneNumberRef.current.value,
          countryCode: countrycodeRef.current.value,
          couponRangeId: selectedOption,
          customerId: userId || "",
          generatedBy: "EzyGoWeb",
          recipientEmail: recipientsEmailRef.current.value,
          recipientName: recipientsRef.current.value,
          recipientCountryCode: recipientsPhoneCodeRef.current.value,
          recipientPhoneNumber: recipientsPhoneRef.current.value
        })
      )
        .then((response) => {
          setisDisabled(false);
          if (response.message) {
            alert(
              "Din kjære har allerede 3 gavekort. Vennligst prøv igjen senere!" ||
                response.message
            );
          } else {
            alert("Coupon submitted successfully!");
          }
        })
        .catch((error) => {
          setisDisabled(false);
          console.error("Error:", error);
          // alert("Noe gikk galt!");
        });
    }
  };
  const columns = [
    {
      title: "Agent",
      children: [
        {
          title: "Kjøpsbeløp",
          dataIndex: "giftCardAmt",
          key: "giftCardAmt",
          // width: 60,
          render: (text) => `${text}`
        },
        {
          title: "Mottaksbeløp",
          dataIndex: "finalAmount",
          key: "finalAmount",
          // width: 60,
          render: (text) => `${text}`
        }
      ]
    },

    {
      title: "Månedlig (%) bruk",
      dataIndex: "monthlyPer",
      key: "monthlyPer",
      // width: 50,
      render: (text) => `${text}%`
    },
    {
      title: "Gyldighet (måneder)",
      dataIndex: "validity",
      key: "validity",
      // width: 50,
      render: (text) => `${text}`
    },
    {
      title: <CheckCircleOutlined />,
      // title: "Velge",
      key: "select",
      align: "center",
      fixed: "right",
      // width: 50,
      render: (_, record) => (
        <Radio
          value={record._id}
          checked={selectedOption === record._id}
          onChange={() => handleSelect(record._id)}
        />
      )
    }
  ];
  const dataSource = Array.isArray(tflGiftCardAmt)
    ? tflGiftCardAmt.map((item) => ({
        ...item,
        key: item._id // Use _id as the unique key for each row
      }))
    : [];
  // console.log("dataSource", dataSource);
  const content = () => {
    return (
      <>
        {" "}
        <Row className="pb-4">
          <Card className="border-radius-10 width-100">
            <Form className="py-3 px-2">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Recipients Name</Form.Label>
                <Form.Control type="email" ref={recipientsRef} />
                {errorObj.recipientsError && (
                  <Form.Control.Feedback
                    className="custom danger"
                    type="invalid"
                  >
                    Vennligst skriv inn en gyldig recipients name.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Recipients Mobilnummer</Form.Label>
                <div className="d-flex ">
                  <Form.Select
                    aria-label="Recipient Country Code"
                    style={{ width: "100px" }}
                    ref={recipientsPhoneCodeRef}
                  >
                    <option value="47">+47</option>
                    <option value="91">+91</option>
                  </Form.Select>

                  <Form.Control
                    aria-label="Text input with dropdown button"
                    ref={recipientsPhoneRef}
                    type="number"
                  />
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Recipients E-Mail</Form.Label>
                <Form.Control type="email" ref={recipientsEmailRef} />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Din e-post</Form.Label>
                <Form.Control type="email" ref={emailRef} />
                {errorObj.emailError && (
                  <Form.Control.Feedback
                    className="custom danger"
                    type="invalid"
                  >
                    Vennligst skriv inn en gyldig e-mail adresse
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Ditt Mobilnummer</Form.Label>
                <div className="d-flex ">
                  <Form.Select
                    aria-label="Default select example"
                    style={{ width: "100px" }}
                    ref={countrycodeRef}
                    onChange={(event) => {
                      setCountryCodeConfirm(event.target.value);
                    }}
                  >
                    <option value="47">+47</option>
                    <option value="91">+91</option>
                  </Form.Select>
                  <Form.Control
                    aria-label="Text input with dropdown button"
                    ref={phoneNumberRef}
                    type="number"
                  />
                </div>

                {errorObj.phoneNumberError && (
                  <Form.Control.Feedback
                    className="custom danger"
                    type="invalid"
                  >
                    Vennligst oppgi et gyldig Mobilnummer.
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Gavekortverdi (NOK)</Form.Label>

                <Table
                  bordered
                  columns={columns}
                  dataSource={dataSource}
                  scroll={{ y: 620 }}
                  // scroll={{ x: 500, y: 620 }}
                  locale={{
                    emptyText: "No data available"
                  }}
                  pagination={false}
                  className="tflTable"
                />
                {errorObj.coupanValueError && (
                  <Form.Control.Feedback
                    className="custom danger"
                    type="invalid"
                  >
                    Vennligst velg en gyldig gavekortverdi.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form>
            <div className="card-footer text-center">
              <Button
                variant="primary"
                onClick={onSubmitCoupon}
                className="btn-primary border-radius-5"
              >
                Kjøpe
              </Button>
            </div>
          </Card>
        </Row>
      </>
    );
  };
  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {isMobile && (
        <div className="px-2 pt-3 pb-5 justify-content-start align-items-start giftCardFormBg vh90andScroll ">
          <Row className="justify-content-center">
            <h2 className="font-20 text-white">Agentkort</h2>
          </Row>
          {content()}
        </div>
      )}
      {!isMobile && (
        <div style={{ marginTop: "80px" }} className="mission profilePages">
          <Row className="justify-content-center">
            <h2 className="font-20">Agentkort</h2>
          </Row>
          <Row className="align-items-center justify-content-center ">
            <Col lg={12} className="">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}

export default TflGiftCardOthers;
