import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Row, Col } from "antd";
import BottomFooter from "../Footer/BottomFooter";
import doll from "../../assets/mobile/doll.webp";
import aryan from "../../assets/aryan.webp";
import profileIcon from "../../assets/profileFallBack.png";
import potali from "../../assets/mobile/potali.webp";
import { s3url } from "../../helper/endpoint";
import { isMobile } from "react-device-detect";

export default function ABoard() {
  const navigate = useNavigate();

  let user = useSelector((state) => state.userReducer);
  let userprofileImage = localStorage.getItem("profileImage");

  const profileImageSrc =
    userprofileImage == "" ? profileIcon : `${s3url}/${userprofileImage}`;
  // console.log("userprofileImage", userprofileImage);
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      {isMobile && (
        <div className="p-3" id="arkivAboard">
          <h1 className="text-center text-white py-5 font-35">Arkiv</h1>
          <Row gutter={[16, 16]} justify="center" className="mt-5">
            <Col span={12}>
              <Card
                className="border-radius-10"
                hoverable
                onClick={() => navigate("/profileInAboard")}
                cover={
                  <img
                    className="border-radius-50"
                    src={profileImageSrc}
                    alt={`${user.firstName}`}
                    style={{ width: "100px", height: "100px" }}
                    loading="lazy"
                  />
                }
              >
                <p
                  className="font-700 font-14 text-center"
                  style={{ color: "#004AAD" }}
                >
                  Personlig Profil
                </p>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                className="border-radius-10"
                hoverable
                onClick={() => navigate("/tflCoinExchange")}
                cover={
                  <img src={potali} style={{ width: "100px" }} loading="lazy" />
                }
              >
                <p
                  className="font-700 font-14 text-center"
                  style={{ color: "#004AAD" }}
                >
                  TFL Mynter
                </p>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                className="border-radius-10"
                hoverable
                onClick={() => navigate("/agentProfile")}
                cover={
                  <img src={doll} style={{ width: "100px" }} loading="lazy" />
                }
              >
                <p
                  className="font-700 font-14 text-center"
                  style={{ color: "#004AAD" }}
                >
                  Agentprofil
                </p>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                className="border-radius-10"
                hoverable
                onClick={() =>
                  window.open("https://ezygo.no/mobile/", "_blank")
                }
                cover={
                  <img src={aryan} style={{ width: "100px" }} loading="lazy" />
                }
              >
                <p
                  className="font-700 font-14 text-center"
                  style={{ color: "#004AAD" }}
                >
                  A-Board
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {!isMobile && (
        <div
          className="  mb-5 mb-5  mission profilePages"
          style={{ marginTop: "64px" }}
          id="aBoardWebPage"
        >
          <h1 className="text-center pt-5 font-35">Arkiv</h1>
          <Row
            gutter={[16, 16]}
            justify="center"
            className=" px-5"
            align="center"
          >
            <Col span={6}>
              <Card
                className="border-radius-10 pt-5"
                hoverable
                onClick={() => navigate("/profileInAboard")}
                cover={
                  <img
                    className="border-radius-50"
                    src={profileImageSrc}
                    alt={`${user.firstName}`}
                    style={{ width: "100px", height: "100px" }}
                  />
                }
              >
                <p className="font-700 font-14 text-center">PERSONLIG PROFIL</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="border-radius-10  pt-5"
                hoverable
                onClick={() => navigate("/tflCoinExchange")}
                cover={<img src={potali} style={{ width: "100px" }} />}
              >
                <p className="font-700 font-14 text-center">TFL MYNTER</p>
              </Card>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            justify="center"
            className=" px-5 mt-3"
            align="center"
          >
            <Col span={6}>
              <Card
                className="border-radius-10 pt-5"
                hoverable
                onClick={() => navigate("/agentProfile")}
                cover={
                  <img src={doll} style={{ width: "100px" }} loading="lazy" />
                }
              >
                <p className="font-700 font-14 text-center">AGENTPROFIL</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="border-radius-10 pt-5"
                hoverable
                onClick={() =>
                  (window.location.href = "https://ezygo.no/mobile/")
                }
                cover={
                  <img src={aryan} style={{ width: "100px" }} loading="lazy" />
                }
              >
                <p className="font-700 font-14 text-center">A-BOARD</p>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
