import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Layout,
  List,
  Row,
  Col,
  Space,
  Typography
} from "antd";
import {
  UserOutlined,
  FileTextOutlined,
  CreditCardOutlined,
  EyeOutlined,
  FileProtectOutlined,
  FileOutlined,
  LogoutOutlined,
  CopyrightOutlined,
  AlignLeftOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { s3url } from "../../helper/endpoint";
import { EditOutlined, CaretRightOutlined } from "@ant-design/icons";
import { userAction } from "../../store/slices/userslices";
import ProfileIcon from "../../assets/profileFallBack.png";
import tflCoin from "../../assets/mobile/tflCoins.png";

const { Text, Title } = Typography;

const ProfileScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let userprofileImage = localStorage.getItem("profileImage");
  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const profileImageSrc =
    userprofileImage == "" ? ProfileIcon : `${s3url}/${userprofileImage}`;
  let user = useSelector((state) => state.userReducer);
  const refreshAfterLogout = () => {
    dispatch(userAction.removeUser());
    window.location.reload();
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);
  const profileSections = [
    {
      title: "Arkiv",
      items: [
        {
          icon: <EditOutlined />,
          text: "Rediger profil",
          path: "/editProfile"
        },
        {
          icon: <CopyrightOutlined />,
          text: "TFL Coins",
          path: "/tflCoinExchange"
        },
        {
          icon: <UserOutlined />,
          text: "Agentprofil",
          path: "/agentProfile"
        }
      ]
    },
    {
      title: "Bestillinger",
      items: [
        {
          icon: <AlignLeftOutlined />,
          text: "Matbestilling Historie",
          path: "/orderHistory"
        },
        {
          icon: <AlignLeftOutlined />,
          text: "Produkt Bestillingshistorikk",
          path: "/orderHistory"
        }
      ]
    },
    {
      title: "Agentkort",
      items: [
        {
          icon: <CreditCardOutlined />,
          text: "Kjøp til deg selv",
          path: "/getTflCouponforme"
        },
        {
          icon: <TeamOutlined />,
          text: "Kjøp for andre",
          path: "/getTflCouponforothers"
        },
        {
          icon: <EyeOutlined />,
          text: "Se Agent Kort",
          path: "/showTflGiftCard"
        }
      ]
    },
    {
      title: "Mer informasjon",
      items: [
        {
          icon: <FileProtectOutlined />,
          text: "Personvernerklæring",
          path: "/home"
        },
        { icon: <FileOutlined />, text: "Vilkår og betingelser", path: "/home" }
      ]
    }
  ];
  return (
    <Layout className="shortCutStyle px-3 py-3">
      <Row className="d-flex justify-content-between">
        <Col span={6}>
          <Avatar
            size={100}
            src={profileImageSrc}
            style={{ marginBottom: "10px" }}
            alt={`${user.firstName}`}
          />
        </Col>
        <Col
          span={15}
          className="flex-column d-flex align-items-start justify-content-center"
        >
          <h4 className="font-18">
            {capitalizeFirstLetter(user.firstName)}{" "}
            {capitalizeFirstLetter(user.lastName)}!👋
          </h4>
          <p>{user.email}</p>
        </Col>
      </Row>
      {profileSections.map((section, index) => (
        <Card
          className="border-radius-5 spreadedShadow"
          key={index}
          style={{ marginBottom: "16px" }}
        >
          <Typography.Title level={5} style={{ marginBottom: "16px" }}>
            {section.title}
          </Typography.Title>
          <List
            dataSource={section.items}
            renderItem={(item) => (
              <List.Item className="d-flex">
                <NavLink
                  to={item.path}
                  style={{ textDecoration: "none" }}
                  className={
                    "d-flex justify-content-between width-100 text-black"
                  }
                >
                  <div className="d-flex align-items-center">
                    {" "}
                    {item.icon}
                    <Text>&nbsp;&nbsp;&nbsp;{item.text}</Text>
                  </div>
                  <CaretRightOutlined className="d-flex align-items-center" />
                </NavLink>
              </List.Item>
            )}
          />
        </Card>
      ))}
      <Button
        onClick={refreshAfterLogout}
        type="primary"
        className="width-100 text-center border-radius-5 mt-5"
        danger
        icon={<LogoutOutlined />}
        size="large"
      >
        Logg ut
      </Button>
    </Layout>
  );
};

export default ProfileScreen;
