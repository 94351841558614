import React, { useState, useEffect } from "react";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { useNavigate } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { rootAction } from "../../store/slices/rootSlices";
import eGiftCard from "../../assets/e-card-logo.png";
import tflSmallLogo from "../../assets/sponsoredByTFL.webp.png";
import {
  Row,
  Col,
  Card,
  Skeleton,
  Popover,
  Alert,
  Button,
  Divider
} from "antd";
import BottomFooter from "../Footer/BottomFooter";

import { FaCopy } from "react-icons/fa";

import redBg from "../../assets/BG/RED.webp";
import orangeBg from "../../assets/BG/ORANGE.webp";
import yellowBg from "../../assets/BG/YELLOW.webp";
import greenBg from "../../assets/BG/GREEN.webp";
import dBlueBg from "../../assets/BG/DARK_BLUE.webp";
import sBlueBg from "../../assets/BG/SKY_BLUE.webp";
import purpleBg from "../../assets/BG/PURPLE.webp";
import dPurpleBg from "../../assets/BG/DARK _PURPLE.webp";
import blackBg from "../../assets/BG/BLACK.webp";
import { isMobile } from "react-device-detect";

export default function ShowTFLGiftCard() {
  let dispatch = useDispatch();
  const [couponData, setCouponData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleCardId, setVisibleCardId] = useState(null);
  const navigate = useNavigate("");

  const user = useSelector((state) => state.userReducer);
  const currentMonth = new Date()
    .toLocaleString("default", { month: "long" })
    .toUpperCase();

  const toggleCodeVisibility = (id) => {
    setVisibleCardId((prevId) => (prevId === id ? null : id));
  };

  const getCoupons = async () => {
    const cardHistoryData = {
      customerPhoneNo: user.phone,
      delStatus: "all"
    };

    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/getusercoupons`,
        cardHistoryData
      );
      setCouponData(response.data.data.coupons);
    } catch (error) {
      console.error("Error fetching coupons:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCoupons();
  }, []);

  const backgroundImages = [
    redBg, // For amountPaid <= 1000
    orangeBg, // For amountPaid <= 2000
    yellowBg, // For amountPaid <= 4000
    greenBg, // For amountPaid <= 8000
    dBlueBg, // For amountPaid <= 16000
    sBlueBg, // For amountPaid <= 32000
    purpleBg, // For amountPaid <= 64000
    dPurpleBg, // For amountPaid <= 128000
    blackBg // For amountPaid <= 256000
  ];

  const thresholds = [
    10, 1000, 2000, 4000, 8000, 16000, 32000, 64000, 128000, 256000
  ];

  const getBackgroundImage = (amountPaid) => {
    // console.log("amountPaid: ", amountPaid);
    for (let i = thresholds.length - 1; i >= 0; i--) {
      if (amountPaid > thresholds[i]) {
        // console.log("amountPaid: ", backgroundImages);
        return backgroundImages[i];
      }
    }
    return backgroundImages[0]; // Default background
  };

  const couponCard = () => {
    return (
      <>
        {couponData.map((data, index) => {
          const handleCopyCode = () => {
            if (data?.coupanCode) {
              navigator.clipboard.writeText(data.coupanCode);
              alert("Kupongkode kopiert til utklippstavlen!");
            }
          };
          const backgroundImage = getBackgroundImage(data.amountPaid || 0);
          const isExpired =
            data.isExpiredCheck == true ||
            (data?.expirayDate && new Date(data.expirayDate) < new Date());
          return (
            <Col xs={24} md={8} xxl={6}>
              <Card
                className={`giftCardBox width-100 mb-4 ${
                  isExpired ? "disabledCard" : ""
                }`}
                key={data._id}
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "20px",
                  opacity: isExpired ? 0.5 : 1,
                  pointerEvents: "auto",
                  border: "none",
                  boxShadow: "none"
                }}
              >
                <Row className="justify-content-between mb-1 text-white">
                  <Col className="d-flex align-items-center">
                    <img alt="eGiftCard" src={eGiftCard} width={"80px"} />
                  </Col>
                  <Col className="text-right">
                    <h3 className="mb-0 font-16 font-700 text-white">
                      NOK {`${data?.coupanValue.toFixed(2)}`}
                    </h3>
                    <p className="mb-0 font-14">
                      BALANCE {`${data?.reamainigValue.toFixed(2)}`}
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-between mb-2">
                  <Col>
                    <h3 className="font-22 text-white mb-0">
                      {visibleCardId === data._id && !isExpired
                        ? data?.coupanCode
                        : "xxxxxx"}
                      &nbsp;
                      <FaCopy
                        onClick={() =>
                          !isExpired && handleCopyCode(data?.coupanCode)
                        } // Disable copy for expired cards
                        style={{
                          cursor: isExpired ? "not-allowed" : "pointer",
                          color: isExpired ? "gray" : "inherit"
                        }}
                      />
                    </h3>
                  </Col>
                  <Col>
                    <Button
                      onClick={() =>
                        !isExpired && toggleCodeVisibility(data._id)
                      } // Disable button for expired cards
                      className="greenAccentBg border-radius-50 font-700"
                      style={{
                        border: "none",
                        cursor: isExpired ? "not-allowed" : "pointer",
                        backgroundColor: isExpired ? "gray" : ""
                      }}
                      disabled={isExpired}
                    >
                      {visibleCardId === data._id ? "Hide Code" : "View Code"}
                    </Button>
                  </Col>
                </Row>
                <Row className="justify-content-between">
                  <Col className="d-flex justify-content-center align-items-center text-white">
                    MÅNED - {currentMonth}
                  </Col>
                  <Col>
                    <p className="mb-0 greenAccentText text-right">
                      GJELDER TIL
                    </p>
                    <p className="mb-0 text-white text-right">
                      {data?.expirayDate
                        ? new Date(data.expirayDate).toISOString().split("T")[0]
                        : "N/A"}
                    </p>
                  </Col>
                </Row>
                <Divider />
                <Row className="justify-content-between my-2">
                  <Col span={4}>
                    <p className="mb-0 greenAccentText">BEGRENSE</p>
                    <p className="mb-0 text-white">
                      {data?.cardAmtForThisMonth.toFixed(2)}
                    </p>
                  </Col>
                  <Col span={4}>
                    <p className="mb-0 greenAccentText">BRUKT</p>
                    <p className="mb-0 text-white">
                      {Math.abs(
                        (Number(data?.cardAmtForThisMonth) || 0) -
                          (Number(data?.remAmtForThisMonth) || 0)
                      ).toFixed(2)}
                    </p>
                  </Col>
                  <Col span={4}>
                    <p className="mb-0 greenAccentText">BALANSERE</p>
                    <p className="mb-0 text-white">
                      {data?.remAmtForThisMonth.toFixed(2)}
                    </p>
                  </Col>
                  <Col span={6}></Col>
                </Row>
                <Divider />
                <Row className="justify-content-between position-relative mt-3">
                  <Col className="d-flex justify-content-center align-items-end">
                    <p className="text-white mb-0">
                      {(() => {
                        if (data.generatedBy === "admin") {
                          return "BEGAVET AV ADMIN";
                        } else if (data.generatedBy === "CoinConversions") {
                          return "MYNTER KONVERTER";
                        } else {
                          return data?.recipientName?.toUpperCase();
                        }
                      })()}
                    </p>
                  </Col>
                  <Col
                    className="position-absolute"
                    style={{ bottom: "0", right: "0" }}
                  >
                    <img
                      src={tflSmallLogo}
                      alt="sponsored by tfl"
                      width={"50px"}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
      </>
    );
  };

  const noGiftCard = () => {
    <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
      <p className="text-dark font-18 font-700">
        Du har ikke kjøpt noen kort ennå!
      </p>
      <a
        className="aboutNavLink greenAccentBg border-radius-10 font-900"
        style={{
          cursor: "pointer",
          padding: "8px 50px"
        }}
        onClick={() => {
          navigate("/tflgiftcard");
        }}
      >
        Kjøp nå
      </a>
    </div>;
  };
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      {isMobile ? (
        <div className="vh90andScroll">
          {loading ? (
            <Row className="skeltonTopMargin">
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Skeleton avatar paragraph={{ rows: 4 }} />
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Skeleton avatar paragraph={{ rows: 4 }} />
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Skeleton avatar paragraph={{ rows: 4 }} />
              </Col>
            </Row>
          ) : (
            <Row className="p-3 ">
              <Col span={24}>
                {couponData.length > 0 ? (
                  <>
                    <h3 class="font-24 font-700">Agentkortliste</h3>
                    {couponCard()}
                  </>
                ) : (
                  <>{noGiftCard()}</>
                )}
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <>
          {loading ? (
            <Row className="skeltonTopMargin">
              <Col lg={{ span: 8 }}>
                <Skeleton avatar paragraph={{ rows: 4 }} />
              </Col>
              <Col lg={{ span: 8 }}>
                <Skeleton avatar paragraph={{ rows: 4 }} />
              </Col>
              <Col lg={{ span: 8 }}>
                <Skeleton avatar paragraph={{ rows: 4 }} />
              </Col>
            </Row>
          ) : (
            <Row
              className="py-3 px-5 flex-column"
              style={{ marginTop: "70px" }}
            >
              {couponData.length > 0 ? (
                <>
                  <h3 class="font-24 font-700">Agentkortliste</h3>
                  <Row gutter={[16, 16]}>{couponCard()}</Row>
                </>
              ) : (
                <>{noGiftCard()}</>
              )}{" "}
            </Row>
          )}
        </>
      )}
      <BottomFooter />
    </>
  );
}
