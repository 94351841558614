import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { LeftCircleOutlined } from "@ant-design/icons";
import { Row, Card, Col, Divider } from "antd";
import axios from "axios";
import { s3url, apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import MapForm from "../Checkout/Forms/MapForm";
import { isMobile } from "react-device-detect";

const TflMarketCart = (props) => {
  const location = useLocation();
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [totalToPay, setTotalToPay] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [couponValue, setCouponValue] = useState(0);
  const [couError, setCouError] = useState("");
  const [loading, setLoading] = useState(false);
  let address = useSelector((state) => state.locationReducer);
  // console.log("address", address);
  const [appliedCouponCode, setAppliedCouponCode] = useState(
    localStorage.getItem("appliedCouponCode") || ""
  );
  const couponRef = useRef();

  // Close modal
  const handleClose = () => {
    setOpenCouponModal(false);
    setCouError("");
  };

  // Apply coupon logic
  const onApplyCoupon = async () => {
    const couponCode = couponRef.current.value.trim();

    if (!couponCode) {
      setCouError("Vennligst skriv inn en gavekortkode");
      return;
    }

    if (couponCode === appliedCouponCode) {
      setCouError("Denne koden er allerede i bruk.");
      return;
    }

    setCouError("");
    setLoading(true);

    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}tdlcoupon/gettflcouponvalueMarket`,
        {
          coupanCode: couponCode
        }
      );
      const couponData = response.data.data;
      if (couponData && couponData.remAmtForThisMonth) {
        setAppliedCouponCode(couponCode);
        setCouponValue(couponData.remAmtForThisMonth);
        localStorage.setItem("appliedCouponCode", couponCode);
        handleClose();
      } else {
        setCouError("Koden er ugyldig eller utløpt");
      }
    } catch (error) {
      console.error("Error fetching coupon value:", error);
      setCouError("Noe gikk galt. Prøv igjen senere.");
    } finally {
      setLoading(false);
    }
  };

  // Delete coupon logic
  const deleteCoupon = () => {
    setAppliedCouponCode(null);
    setCouponValue(0);
    localStorage.removeItem("appliedCouponCode");
    setCouError("");
  };

  const { totalAmount, totalWeight } = location.state;

  // Fetch cart data and calculate delivery charges
  useEffect(() => {
    const data = localStorage.getItem("TFLCartData");
    if (data) {
      setCartData(JSON.parse(data));
    }

    if (location.state && location.state.deliveryCharges) {
      const {
        deliveryCharges,
        totalLength,
        totalBreadth,
        totalHeight,
        totalWeight
      } = location.state;

      let priceList = [];
      let allPrices = [];

      console.log("deliveryCharges Array: ", deliveryCharges);

      // Iterate through the deliveryCharges and apply the logic to filter valid prices
      for (let i = 0; i < deliveryCharges.length; i++) {
        const currentCharge = deliveryCharges[i];
        allPrices.push(currentCharge.price);

        if (
          totalWeight >= currentCharge.weight.min &&
          totalWeight <= currentCharge.weight.max &&
          totalLength <= currentCharge.dimensions.length &&
          totalBreadth <= currentCharge.dimensions.breadth &&
          totalHeight <= currentCharge.dimensions.height
        ) {
          console.log("Valid delivery charge: ", currentCharge);
          priceList.push(currentCharge.price);
        }
      }

      console.log("allPrices: ", allPrices);
      console.log("priceList: ", priceList);

      // Determine the minimum price from the valid priceList or fallback to allPrices
      let minPrice = 0;
      if (priceList.length > 0) {
        minPrice = Math.min(...priceList); // Get the minimum value from the valid prices
      } else {
        minPrice = Math.max(...allPrices); // Fallback to the maximum from all prices if no valid prices
      }

      console.log("Calculated Minimum Price (minPrice): ", minPrice);

      // Set the calculated delivery charge
      setDeliveryCharges(minPrice);
    }
  }, [location.state]);

  // Recalculate total amount when coupon or delivery charge changes
  useEffect(() => {
    // Extract delivery charges value (handling array)
    const deliveryChargeValue = Array.isArray(deliveryCharges)
      ? deliveryCharges[0] || 0
      : deliveryCharges;

    let totalAmountToPay = totalAmount + deliveryChargeValue;
    let finalAmount = totalAmountToPay - couponValue;

    console.log(
      "totalAmountToPay:",
      totalAmountToPay,
      "totalAmount:",
      totalAmount,
      "deliveryCharges:",
      deliveryChargeValue,
      "couponValue:",
      couponValue
    );

    // If finalAmount is negative, set it to 0
    if (finalAmount < 0) {
      finalAmount = 0;
    }

    setTotalToPay(finalAmount);
  }, [totalAmount, deliveryCharges, couponValue]);

  // Create order payload
  const onCreateOrder = async (cart) => {
    setDisabled(true);
    const orderPayload = {
      items: cart.map((item) => ({
        _id: item._id,
        quantity: item.quantity
      })),
      customerData: {
        address: address?.locationAddress,
        countryCode: localStorage.getItem("userCountryCode"),
        email: localStorage.getItem("userEmail"),
        firstName: localStorage.getItem("userFirstName"),
        lastName: localStorage.getItem("userLastName"),
        phoneNumber: localStorage.getItem("userPhone")
      },
      appUserId: localStorage.getItem("userId"),
      customerType: "IOS",
      orderFrom: "TDP",
      coupanCode: localStorage.getItem("appliedCouponCode"),
      couponValue: couponValue,
      coinsUsed: 0
    };
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedrivepayment/createordertfl`,
        orderPayload,
        {
          headers: {
            "x-auth-token": `${localStorage.getItem("token")}`
          }
        }
      );
      console.log("orderPayload: " + JSON.stringify(orderPayload));
      console.log("response: " + JSON.stringify(response));
      if (response.status === 200) {
        const orderResponseUrl = response.data.data.data.url;
        window.location.replace(orderResponseUrl);
      }
    } catch (error) {
      console.error("Error creating order:", error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <Row className="px-3" style={{ marginTop: "70px" }}>
            <Col
              span={24}
              className="mt-3 border-radius-10"
              style={{
                height: "50vh",
                overflowY: "auto",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                backgroundColor: "#f9f9f9"
              }}
            >
              {cartData.map((item) => (
                <Card
                  key={item._id}
                  className="mb-3 border-radius-10 tflMarketProCard"
                  style={{
                    border: "1px solid #eaeaea",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                  }}
                >
                  <Row className="d-flex justify-content-between align-items-center border-radius-5">
                    <Col span={6}>
                      <img
                        style={{
                          width: "60px",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"
                        }}
                        alt={item.name}
                        src={`${s3url}/${item.url}`}
                      />
                    </Col>
                    <Col span={17} className="pl-3">
                      <p className="font-12 font-700 mb-0">
                        {item.productName} &times; {item.quantity}
                      </p>
                      <p style={{ margin: "5px 0 0", color: "#555" }}>
                        {item.amount * item.quantity} Kr.
                      </p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Col>
          </Row>

          <Row
            className="mt-4 width-100 position-fixed"
            style={{ bottom: "0" }}
          >
            <Col span={24}>
              <Card
                className="order-radius-10"
                style={{
                  border: "1px solid #eaeaea",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                }}
              >
                <Divider>Tilbud og fordeler</Divider>
                <Button
                  onClick={() => setOpenCouponModal(true)}
                  className="width-100"
                >
                  Søk om agentkort
                </Button>{" "}
                <br />
                <br /> <Divider>Fakturadetaljer</Divider>
                <div className="d-flex justify-content-between">
                  <p style={{ margin: 0, color: "#555" }}>Total</p>
                  <p style={{ margin: 0, fontWeight: "bold" }}>
                    {totalAmount} Kr.
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p style={{ margin: 0, color: "#555" }}>Frakt Pris</p>
                  <p style={{ margin: 0, fontWeight: "bold" }}>
                    +{deliveryCharges} Kr.
                  </p>
                </div>
                {couponValue <= 0 ? (
                  ""
                ) : (
                  <div className="d-flex justify-content-between">
                    <p style={{ margin: 0, color: "#555" }}>
                      Agent Card Amount
                    </p>
                    <p style={{ margin: 0, fontWeight: "bold" }}>
                      -{couponValue} Kr.
                    </p>
                  </div>
                )}
                <br />
                <br />
                <Button
                  type="primary"
                  className="w-100 saveBtn text-white font-900 font-16 border-radius-5"
                  style={{ backgroundColor: "#1890ff" }}
                  block
                  onClick={() => onCreateOrder(cartData)}
                  disabled={disabled}
                >
                  Pay {totalToPay}Kr.
                </Button>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row
            className="px-3 justify-content-center align-items-start"
            style={{ marginTop: "90px" }}
            gutter={[24, 24]}
          >
            <Col span={8} className="border-radius-10">
              <div className="width-100 align-items-center justify-content-end">
                <button
                  onClick={() => window.history.back()}
                  className="d-flex align-items-center justify-content-center border-radius-50 text-white saveBtn px-4"
                >
                  <LeftCircleOutlined />
                  &nbsp; Lukk
                </button>
              </div>
              <Divider>Se gjennom bestillingen din</Divider>{" "}
              <div
                className="width-100 d-flex align-items-start justify-content-start flex-column mb-3"
                style={{
                  height: "40vh",
                  overflowY: "auto",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  padding: "10px",
                  backgroundColor: "#f9f9f9"
                }}
              >
                {" "}
                {cartData.map((item) => (
                  <Card
                    key={item._id}
                    className="mb-3 border-radius-10 tflMarketProCard width-100"
                    style={{
                      border: "1px solid #eaeaea",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <Row className="d-flex justify-content-between align-items-center border-radius-5 width-100">
                      <Col span={6}>
                        <img
                          style={{
                            width: "60px",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"
                          }}
                          alt={item.name}
                          src={`${s3url}/${item.url}`}
                        />
                      </Col>
                      <Col span={17} className="pl-3">
                        <p className="font-12 font-700 mb-0">
                          {item.productName} &times; {item.quantity}
                        </p>
                        <p style={{ margin: "5px 0 0", color: "#555" }}>
                          {item.amount * item.quantity} Kr.
                        </p>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </div>
              <Card
                className="order-radius-10"
                style={{
                  border: "1px solid #eaeaea",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                }}
              >
                <Divider>Tilbud og fordeler</Divider>
                <Button
                  onClick={() => setOpenCouponModal(true)}
                  className="width-100"
                >
                  Søk om agentkort
                </Button>{" "}
                <br />
                <br /> <Divider>Fakturadetaljer</Divider>
                <div className="d-flex justify-content-between">
                  <p style={{ margin: 0, color: "#555" }}>Total</p>
                  <p style={{ margin: 0, fontWeight: "bold" }}>
                    {totalAmount} Kr.
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p style={{ margin: 0, color: "#555" }}>Frakt Pris</p>
                  <p style={{ margin: 0, fontWeight: "bold" }}>
                    +{deliveryCharges} Kr.
                  </p>
                </div>
                {couponValue <= 0 ? (
                  ""
                ) : (
                  <div className="d-flex justify-content-between">
                    <p style={{ margin: 0, color: "#555" }}>
                      Agent Card Amount
                    </p>
                    <p style={{ margin: 0, fontWeight: "bold" }}>
                      -{couponValue} Kr.
                    </p>
                  </div>
                )}
                <br />
                <br />
                <Button
                  type="primary"
                  className="w-100 saveBtn text-white font-900 font-16 border-radius-5"
                  style={{ backgroundColor: "#1890ff" }}
                  block
                  onClick={() => onCreateOrder(cartData)}
                  disabled={disabled}
                >
                  Pay {totalToPay}Kr.
                </Button>
              </Card>
            </Col>
          </Row>
        </>
      )}

      {openCouponModal && (
        <Modal
          show={openCouponModal}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>Bruk Agentkort</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="grow" style={{ color: "black" }} />
                </div>
              ) : (
                <Form.Group className="mb-3" controlId="couponInput">
                  <Form.Control
                    type="text"
                    ref={couponRef}
                    placeholder={
                      appliedCouponCode
                        ? "Lim inn ny kode"
                        : "Lim inn koden din"
                    }
                    autoFocus
                  />
                  {couError && (
                    <Form.Text className="text-danger">{couError}</Form.Text>
                  )}
                </Form.Group>
              )}
            </Form>
            {appliedCouponCode && (
              <div
                style={{
                  padding: "16px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginTop: "16px"
                }}
              >
                <h4 style={{ margin: 0, color: "#333", fontWeight: "bold" }}>
                  Gjeldende brukt kupongkode
                </h4>
                <p style={{ margin: "8px 0", color: "#555", fontSize: "14px" }}>
                  <strong>Kupongkode:</strong> {appliedCouponCode || "None"}
                </p>
                <button
                  className="text-white font-14 border-radius-5"
                  onClick={deleteCoupon}
                  style={{
                    backgroundColor: "#e74c3c",
                    border: "none",
                    padding: "8px 16px",
                    cursor: "pointer"
                  }}
                >
                  Slett kupong
                </button>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Avbryt
            </Button>
            <Button variant="primary" onClick={onApplyCoupon}>
              {appliedCouponCode ? "Endre kode" : "Bruk kode"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default TflMarketCart;
