import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isMobile } from "react-device-detect";
import {
  Card,
  Avatar,
  Typography,
  Input,
  Row,
  Col,
  DatePicker,
  Select
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";
import { userAction } from "../../store/slices/userslices";
import { s3url, apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import BottomFooter from "../Footer/BottomFooter";
import profileIcon from "../../assets/profileFallBack.png";

const { Title } = Typography;
const { Option } = Select;

export default function EditProfile() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  let [alertMsg, setAlertMsg] = useState("");
  const [formData, setFormData] = useState({});
  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };

  let user = useSelector((state) => state.userReducer);
  let userprofileImage = localStorage.getItem("profileImage");

  const profileImageSrc =
    userprofileImage == "" ? profileIcon : `${s3url}/${userprofileImage}`;
  const handleSubmit = async (e) => {
    e.preventDefault();

    let firstName = e.target.inputFirstName.value;
    let lastName = e.target.inputLastName.value;
    let token = user.token;

    let inputFields = [firstName, lastName, token];

    if (!validateForm(inputFields)) {
      // console.log(firstName, lastName, token);
    } else {
      setAlertMsg("Vennligst vent...");

      let formData = {
        firstName: firstName,
        lastName: lastName
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/updateuser`, formData, {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        })
        .then((response) => {
          // console.log("---response server---", response);

          if (response.data.status == 200) {
            setAlertMsg("Profile updated successfully.");

            let updateData = {
              firstName: response.data.data.firstName,
              lastName: response.data.data.lastName,
              email: user.email,
              phone: user.phone,
              countryCode: user.countryCode,
              id: user._id,
              refferalCode: user.userReferralCode,
              token: user.token
            };

            dispatch(userAction.saveUser(updateData));
          } else {
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          // console.log("---error user---", error.response.data.message);
        });
    }
  };

  const 
  
  
  content = () => {
    return (
      <Card className="border-radius-10">
        <Row
          className="justify-content-between align-items-center
      "
        >
          <Col span={18}>
            <h4>Rediger profil</h4>
          </Col>
          <Col span={4}></Col>
          <Col
            span={24}
            className="mt-2 d-flex flex-column align-items-center justify-content-center"
          >
            {" "}
            <Avatar
              size={100}
              src={profileImageSrc}
              style={{ marginBottom: "10px" }}
              alt={`${user.firstName}`}
            />
            {/* edit image is not working */}
            <Title level={5}>{`${user.firstName} ${user.lastName}`}</Title>
          </Col>{" "}
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="row  mb-3 mt-2" controlId="inputFirstName">
              <Col sm={24} className="mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Fornavn*">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Fornavn"
                    name="inputFirstName"
                    defaultValue={user.firstName || ""}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        firstName: e.target.value
                      }));
                    }}
                  />
                </FloatingLabel>
              </Col>

              <Col sm={24}>
                <FloatingLabel controlId="floatingInputGrid" label="Etternavn*">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Etternavn"
                    name="inputLastName"
                    defaultValue={user.lastName}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        lastName: e.target.value
                      }));
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Form.Group>

            <Form.Group className="row mb-3" controlId="formBasicEmail">
              <Col sm={24} className="mb-3">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Mobilnummer*"
                >
                  <Form.Control
                    disabled={true}
                    required
                    type="number"
                    placeholder="Mobilnummer"
                    name="inputPhone"
                    defaultValue={user.phone}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={24}>
                <FloatingLabel controlId="floatingInputGrid" label="E-post*">
                  <Form.Control
                    disabled={true}
                    required
                    type="email"
                    placeholder="name@example.com"
                    name="inputEmail"
                    defaultValue={user.email}
                  />
                </FloatingLabel>
              </Col>
            </Form.Group>

            <Row>
              <span style={{ color: "#333" }}>{alertMsg}</span>
            </Row>

            <Row className="justify-content-center mt-2">
              <Button variant="primary" type="submit">
                Oppdater profil
              </Button>
            </Row>
          </Form>
        </Row>
      </Card>
    );
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll">{content()}</Row>
      ) : (
        <div style={{ marginTop: "70px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col span={8} className="commonShadowAndRound my-5">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
